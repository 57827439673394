
/***
 *Display type of section when the user create a new section
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update to typescript.
 */
import Vue from "vue";
import { Component } from "vue-property-decorator";

import { required, minLength } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import { mapGetters } from "vuex";
import Contains from "@/utils/SemiColValidator";
import SelectDropDownAdd from "@/components/project/SingleProject/Schematic/SelectDropDownAdd.vue";
import { Device } from "@/interface/DeviceInterface";
import Project from "@/model/Project";
import SectionImagesSelection from "@/components/project/SingleProject/NewSectionTypes/SectionImagesSelection.vue";
import i18n from "@/i18n";
@Component({
  name: "DisplayType",
  components: {
    SelectDropDownAdd,
    Multiselect,
    SectionImagesSelection
  },
  computed: {
    ...mapGetters(["getAllNewAndBookedDevices"]),
    ...mapGetters("projects", {
      currentProject: "GetCurrentProject",
      displayTypes: "GetDisplayTypesList"
    })
  }
})
export default class DisplayType extends Vue {
  /*----------  localData ----------*/

  batteryNumberOptions: Array<number> = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  solarPanelNumberOptions: Array<number> = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

  readonly getAllNewAndBookedDevices!: Array<Device>;
  readonly currentProject!: Project;
  readonly displayTypes!: Array<DisplayType>;

  validations() {
    return {
      displaySection: {
        title: {
          required,
          minLength: minLength(2)
        },
        kilometrage: {
          required,
          minLength: minLength(2),
          notSemiCol: Contains(";")
        }
      }
    };
  }

  value: string = "";
  displaySection: { [key: string]: any[string] } = {
    title:  i18n.t("projectReport.sectionOptions.display"),
    kilometrage: "000",
    displayOneTypeId: "",
    displayTwoTypeId: "",
    mainboardOneSerial: "",
    mainboardTwoSerial: "",
    backgroundImage: "none.svg",
    cbRadioEnabledOne: false,
    cbRadioEnabledTwo: false,
    deviceOneBatteryNumber: 1,
    deviceTwoBatteryNumber: 1,
    deviceOneSolarPanelNumber: 1,
    deviceTwoSolarPanelNumber: 1
  };
  get checkSerialNumbers() {
    if (this.displaySection.mainboardOneSerial && this.displaySection.mainboardTwoSerial) {
      let boardOneSerial = this.displaySection.mainboardOneSerial;
      let boardTwoSerial = this.displaySection.mainboardTwoSerial;
      if (boardOneSerial === boardTwoSerial) {
        return true;
      } else {
        return false;
      }
    } else return false;
  }

  updateTheDeviceValue({ value, name }: { value: any; name: string }) {
    this.displaySection[`${name}`] = value ? value.serialNumber : null;
  }

  HandleCreateSection() {
    let { displayOneTypeId, displayTwoTypeId } = this.displaySection;
    this.displaySection.displayOneTypeId = displayOneTypeId ? displayOneTypeId.id : this.currentProject.isFPU ? 3 : 1;
    this.displaySection.displayTwoTypeId = displayTwoTypeId ? displayTwoTypeId.id : this.currentProject.isFPU ? 3 : 1;

    this.$emit("create-section", this.displaySection);
  }
  HandleImgChange(imageName: string) {
    this.displaySection.backgroundImage = imageName;
  }
}
