
/**
 * Schematic view top  camera type Section.
 *
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update to typescript.
 */

import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import SelectDropDownAdd from "@/components/project/SingleProject/Schematic/SelectDropDownAdd.vue";
import Contains from "@/utils/SemiColValidator";
import SectionImagesSelection from "@/components/project/SingleProject/NewSectionTypes/SectionImagesSelection.vue";
import { Device } from "@/interface/DeviceInterface";
import Multiselect from "vue-multiselect";
import i18n from "@/i18n";

@Component({
  name: "CameraType",
  components: {
    SelectDropDownAdd,
    SectionImagesSelection,
    Multiselect
  },
  computed: {
    ...mapGetters(["getAllDevicesForMeasureAndCamera"])
  }
})
export default class CameraType extends Vue {

   /*----------  localData ----------*/
  
  batteryNumberOptions: Array<number> = [0,1,2,3,4,5,6,7,8,9];
  solarPanelNumberOptions: Array<number> = [0,1,2,3,4,5,6,7,8,9];
  /*----------  Vuex Store  ----------*/
  private getAllDevicesForMeasureAndCamera!: Array<Device>;

  /*----------  validators  ----------*/
  validations() {
    return {
      camera: {
        title: {
          required,
          minLength: minLength(2)
        },
        kilometrage: {
          required,
          minLength: minLength(2),
          notSemiCol: Contains(";")
        }
      }
    };
  }

  /*----------  Local data  ----------*/
  camera: { [key: string]: any[string] } = {
    title: i18n.t("projectReport.sectionOptions.camera"),
    kilometrage: "000",
    mainboardOneSerial: "",
    backgroundImage: "none.svg",
    cbRadioEnabledOne: false,
    deviceOneBatteryNumber: 1,
    deviceOneSolarPanelNumber: 1
  };

  /*----------  Methods  ----------*/
  updateTheDeviceValue({ value, name }: { value: any; name: string }) {
    this.camera[`${name}`] = value ? value.serialNumber : null;
  }

  HandleImgChange(imageName: string) {
    this.camera.backgroundImage = imageName;
  }
}
