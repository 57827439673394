














































































































































import { Component, Vue } from "vue-property-decorator";
import { required, minLength } from "vuelidate/lib/validators";

import { mapGetters } from "vuex";
import SelectDropDownAdd from "@/components/project/SingleProject/Schematic/SelectDropDownAdd.vue";
import Contains from "@/utils/SemiColValidator";
import SectionImagesSelection from "@/components/project/SingleProject/NewSectionTypes/SectionImagesSelection.vue";
import { Device } from "@/interface/DeviceInterface";
import Multiselect from "vue-multiselect";
import i18n from "@/i18n";

@Component({
  name: "MeasurementType",
  components: {
    SelectDropDownAdd,
    SectionImagesSelection,
    Multiselect
  },
  computed: {
    ...mapGetters(["getAllDevicesForMeasureAndCamera"])
  },
  validations: {
    measure: {
      title: {
        required,
        minLength: minLength(2)
      },
      kilometrage: {
        required,
        minLength: minLength(2),
        notSemiCol: Contains(";")
      }
    }
  }
})
export default class MeasurementType extends Vue {
  /*----------  localData ----------*/

  batteryNumberOptions: Array<number> = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  solarPanelNumberOptions: Array<number> = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

  /*----------  Vuex store  ----------*/
  private getAllDevicesForMeasureAndCamera!: Array<Device>;

  measure: { [key: string]: any[any] } = {
    title: i18n.t("projectReport.sectionOptions.measure"),
    kilometrage: "000",
    mainboardOneSerial: "",
    backgroundImage: "none.svg",
    cbRadioEnabledOne: false,
    radarOnLeftSide: false,
    deviceOneBatteryNumber: 1,
    deviceOneSolarPanelNumber: 1
  };

  updateTheDeviceValue({ value, name }: any) {
    this.measure[`${name}`] = value ? value.serialNumber : null;
  }

  HandleImgChange(imageName: string) {
    this.measure.backgroundImage = imageName;
  }

  handleRadarOnLeftSideChange() {
    this.$emit("radar-on-left-side-change", this.measure.radarOnLeftSide);
  }
}
