
/**
 * Component where the user can select the image that go in to the schemativ vue for the yellow lines
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import Project from "@/model/Project";

import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";
import Section from "@/model/ModelSection";

@Component({
  name: "SectionImagesSelection",
  computed: {
    ...mapGetters("projects", {
      currentProject: "GetCurrentProject",
      currentSectionDetails: "GetCurrentSectionDetails"
    })
  }
})
export default class SectionImagesSelection extends Vue {
  /*----------  Props  ----------*/
  @Prop({ default: "none", type: String }) backgroundImage!: string;
  @Prop({ type: Object, default: () => new Section() }) section!: Section;
  @Prop({ default: "", type: String }) type!: string;
  @Prop({ default: false, type: Boolean }) radarOnLeftSide!: boolean;
  @Prop({ type: String }) mainboardOneSerial!: string;
  @Prop({ default: "", type: String }) mainboardTwoSerial!: string;
  @Prop({ default: "" }) displaySection!: any;

  // @Prop(Boolean) readonly radarOnLeftSide!: boolean;

  /*----------  Vuex  ----------*/
  readonly currentProject!: Project;
  readonly currentSectionDetails!: any;


  /*----------  Local data  ----------*/
  showDefault = false;

  linesOptions: any = [];
  imageLineSelected: string = "none";

  checkedLeft = false;
  checkedRight = false;

  imgPos: number = -1;
  radarIsOnLeftSide: boolean = this.radarOnLeftSide;

  created() {
    this.handleSelectPosition();
    this.HandleBackgroundimageChange();
  }

  mounted() {
    this.setNewImgWithOldPosition(this.imgPos);
  }

  /*----------  Watch  ----------*/
  @Watch("backgroundImage", { immediate: true })
  HandleBackgroundimageChange() {
    this.imageLineSelected = this.backgroundImage
      ? this.backgroundImage.replace(".svg", "").replace(".png", "")
      : "none";

    this.setImgOnInit();
  }

  @Watch("checkedRight", { immediate: true })
  toggleRight() {
    this.setNewImgWithOldPosition(this.imgPos);
  }

  @Watch("checkedLeft", { immediate: true })
  toggleLeft() {
    this.setNewImgWithOldPosition(this.imgPos);
  }

  @Watch("radarOnLeftSide", { immediate: true })
  updateToggleRadarOnLeftSide(showDefault: boolean) {



    this.setRightAndLeftPosition(showDefault);
    this.changeImgWithOldPosition();
  }

  setImgOnInit() {
    var backgroundImageCopy = this.backgroundImage;

    if (backgroundImageCopy) {
      var position = backgroundImageCopy.split("-");

      if (position.length > 0) {
        var lastPosition = position.pop();
        if (lastPosition) {
          var side = lastPosition.split(".")[0];

          if (side) {
            if (side === "left") {
              this.radarOnLeftSide = true;
              this.checkedLeft = true;
              this.checkedRight = false;
            } else if (side === "right") {
              this.radarOnLeftSide = false;
              this.checkedLeft = false;
              this.checkedRight = true;
            } else if (side === "both") {
              this.checkedLeft = true;
              this.checkedRight = true;
            }
          } else {
            console.error("Side is undefined, null, or empty.");
          }
        } else {
          console.error("Last position is undefined, null, or empty.");
        }
      } else {
        console.error("Position array is empty.");
      }
    } else {
      console.error("Background image copy is undefined, null, or empty.");
    }

    this.handleSelectPosition();

    var positionArr = this.linesOptions.indexOf(this.imageLineSelected);
    this.imgPos = positionArr;
  }

  setRightAndLeftPosition(radarOnLeftSide: boolean) {

    if(this.type != "display") {
      if (radarOnLeftSide) {
        if (this.checkedRight) {
          this.checkedRight = false;
          this.checkedLeft = true;
        } else {
          this.checkedRight = false;
        }
      } else {
        if (this.checkedLeft) {
          this.checkedLeft = false;
          this.checkedRight = true;
        } else {
          this.checkedLeft = false;
        }
      }
    }

  }

  changeImgWithOldPosition() {
    var position = this.linesOptions.indexOf(this.imageLineSelected);
    this.handleSelectPosition();
    this.setNewImgWithOldPosition(position);
  }

  setNewImgWithOldPosition(position: any) {
    if (position == -1) {
      position = 0;
    }

    this.imageLineSelected = this.linesOptions[position];
    this.handleSelecImage(this.imageLineSelected);
  }

  /*----------  Methods  ----------*/
  getImage(imageName: string) {
    let image = require(`@/assets/images/projects/RZP_BG/options/png/lines-${imageName}.png`);
    return image;
  }

  handleSelecImage(imageName: string) {
    this.imageLineSelected = imageName;
    this.$emit("schematic-img-change", `${imageName}.png`);

    this.imgPos = this.linesOptions.indexOf(this.imageLineSelected);

    if (this.imgPos == -1) {
      this.imgPos = 0;
    }
  }

  handleSelectPosition() {
    this.linesOptions = [
      "none",
      "single-bottom",
      "single-start",
      "single-center",
      "single-end",
      "double-start",
      "double-center",
      "double-end"
    ];

    if (this.checkedLeft && this.checkedRight) {
      this.linesOptions[0] = "none-green-both";
      this.linesOptions[1] = "single-bottom-green-both";
      this.linesOptions[3] = "single-center-green-both";
      this.linesOptions[6] = "double-center-green-both";
    } else if (this.checkedLeft) {
      this.linesOptions[0] = "green-left";
      this.linesOptions[1] = "single-bottom-green-left";
      this.linesOptions[3] = "single-center-green-left";
      this.linesOptions[6] = "double-center-green-left";
    } else if (this.checkedRight) {
      this.linesOptions[0] = "green-right";
      this.linesOptions[1] = "single-bottom-green-right";
      this.linesOptions[3] = "single-center-green-right";
      this.linesOptions[6] = "double-center-green-right";
    }
  }
}
