
/**
 * Create new section  with the information type.
 *
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update to typescript.
 */

import { Component, Vue, Mixins } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import Contains from "@/utils/SemiColValidator";
import SectionImagesSelection from "@/components/project/SingleProject/NewSectionTypes/SectionImagesSelection.vue";
import KmFormatted from "@/mixins/Project/KilometrageFromatted";
import UserAccess from "@/model/User/UserAccess";
import Project from "@/model/Project";
import SelectDropDownAdd from "@/components/project/SingleProject/Schematic/SelectDropDownAdd.vue";
@Component({
  name: "VwaType",
  components: {
    SelectDropDownAdd,
    SectionImagesSelection
  },
  computed: {
    ...mapGetters(["getAllNewAndBookedDevices"])
  },
  validations: {
    vwaSection: {
      title: {
        required,
        minLength: minLength(2)
      },
      kilometrage: {
        required,
        minLength: minLength(2),
        notSemiCol: Contains(";")
      }
    }
  }
})
export default class VwaType extends Vue {
  vwaSection = {
    title: "VWA",
    kilometrage: "000",
    img: "",
    backgroundImage: "none.svg",
    radarOnLeftSide: false,
    displayOneTypeId: 4,
    displayTwoTypeId: 4
  };

  HandleImgChange(imageName: string) {
    this.vwaSection.backgroundImage = imageName;
  }

  handleRadarOnLeftSideChange() {
    this.$emit("radar-on-left-side-change", this.vwaSection.radarOnLeftSide);
  }
}
