
/**
 * Create new section  with the information type.
 *
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update to typescript.
 */

import { Component, Vue } from "vue-property-decorator";
import { required, minLength } from "vuelidate/lib/validators";
import Contains from "@/utils/SemiColValidator";
import SectionImagesSelection from "@/components/project/SingleProject/NewSectionTypes/SectionImagesSelection.vue";

@Component({
  name: "InformationType",
  components: {
    SectionImagesSelection
  },
  validations: {
    informationSection: {
      title: {
        required,
        minLength: minLength(2)
      },
      kilometrage: {
        required,
        minLength: minLength(2),
        notSemiCol: Contains(";")
      }
    }
  }
})
export default class InformationType extends Vue {
  options = [
    { text: "Exit", value: "Exit" },
    { text: "Abfahrt", value: "Abfahrt" },
    { text: "Parkplatz", value: "Parkplatz" },
    { text: "Nothaltebucht", value: "Nothaltebucht" },
    { text: "Tankstelle", value: "Tankstelle" }
    // { text: "Nullpunkt Baustelle", value: "NullpunktBaustelle" }
  ];
  informationSection = {
    title: "Information",
    kilometrage: "000",
    img: "",
    backgroundImage: "none.svg"
  };

  HandleImgChange(imageName: string) {
    this.informationSection.backgroundImage = imageName;
  }
}
